<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_enteringAchieveUpdate" />

      <!-- 検索項目-->
      <v-container fluid>
        <v-row class="search-row">
          <!-- 取引先-->
          <div class="search-autocomplete first-search-item">
            <v-text-field
              dense
              readonly
              v-model="suppliersSelected"
              :label="$t('label.lbl_supplier')"
              class="txt-single"
              persistent-hint
              margin-bottom="0"
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 入庫日-->
          <div class="serch-textbox-arrivalNo">
            <v-text-field
              readonly
              dense
              v-model="enteringAchieveDate"
              :label="$t('label.lbl_enteringDate')"
              class="txt-single"
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 入荷No -->
          <div class="serch-textbox-arrivalNo">
            <v-text-field
              readonly
              dense
              maxlength="50"
              v-model="txt_slipNo"
              :label="$t('label.lbl_slipNo')"
              class="txt-single"
            />
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 品質区分-->
          <div class="search-autocomplete">
            <v-text-field
              readonly
              dense
              v-model="qualitySelected"
              :label="$t('label.lbl_arrivalStatus')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>
          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <!-- 有償/無償-->
          <div style="float: left">
            <v-btn-toggle
              :disabled="true"
              mandatory
              v-model="isPaid"
              color="blue darken-2"
              id="btn-toggle-btn-padding"
            >
              <v-btn
                :disabled="true"
                class="paid-btn"
                v-for="item in freeCostDivList"
                :key="item.value"
                :value="item.value"
                small
              >
                {{ item.text }}
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-row>

        <v-row>
          <div class="search-row-exeBtn first-search-item">
            <div class="btn-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn color="primary" class="api-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <div class="btn-area">
              <!-- 更新登録ボタン-->
              <v-btn color="primary" class="api-btn" value="1" v-on:click="updateInsert">
                {{ $t("btn.btn_updateInsert") }}
              </v-btn>

              <!-- メッセージダイアログ-->
              <ConfirmDialog
                :isShow.sync="ConfirmDialog.isOpen"
                :message="ConfirmDialog.message"
                :okAction="updateInsertApi"
                :redMessage="ConfirmDialog.redMessage"
                :screenFlag="ConfirmDialog.screenFlag"
                :changeFlag="ConfirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-container fluid>
        <v-form ref="editedList">
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- 商品コード・品名 -->
            <template v-slot:[`item.productCd`]="{ item }">
              <div>{{ item.itemCd }}</div>
              <div>{{ item.itemName }}</div>
            </template>

            <template v-slot:[`item.actualQuantity`]="{ item, index }">
              <!-- 実績数-->
              <div
                id="table-actualQuantity"
                v-if="item.oriActualQuantity == 0 || item.oriSubtraction == 0"
              >
                {{ item.actualQuantity }}
              </div>
              <div v-else>
                <v-text-field
                  class="input-number"
                  dense
                  outlined
                  v-model="item.actualQuantity"
                  @change="changeQty(item, index)"
                  :rules="[rules.isNumber]"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </div>
            </template>

            <template v-slot:[`item.detail`]="{ item }">
              <!-- 詳細-->
              <v-btn small @click="deleteItem(item)" text><v-icon>mdi-pencil</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-form>

        <v-dialog v-model="updateDialogMessage" :max-width="800">
          <!-- 戻るボタンのダイアログ -->
          <v-card>
            <v-card-title class="blue-grey lighten-3" primary-title>
              {{ $t("btn.btn_ok") }}
            </v-card-title>
            <v-card-text class="pa-4">
              <p>{{ backMessage }}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="updateOk"> OK </v-btn>
              <v-btn @click="updateNg"> NO </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
      />
      <v-dialog v-model="dialogLocation" :max-width="800">
        <LocationDialog
          :isOpenDetailDialog.sync="dialogLocation"
          v-on:setLocation="saveLocation"
          :locInfo="locInfo"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import LocationDialog from "../../views/common/Location";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";

export default {
  name: "EnteringAchieveUpdateDelete",
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    LocationDialog,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
      referenceDate: "",
    },
    // ロケーションダイアログ
    dialogLocation: false,
    // ロケーション登録に渡す製品コード
    locProductCd: "",
    // 一覧index
    editedIndex: -1,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    // 入荷No
    txt_slipNo: "",
    // 有償/無償
    isPaid: "",
    freeCostDivList: [],
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    // 入庫日
    enteringAchieveDate: "",
    // 品質区分
    qualitySelected: "",
    // メニュー
    openMenu: null,
    inputList: [],
    // 削除リスト
    deleteList: [],
    // 今後の子画面で使用予定
    locationSidList: [],
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // 入庫実績ヘッダ
    headerItems: [
      {
        text: "No",
        class: "listHeader",
        value: "No",
        width: "4%",
        sortable: false,
        align: "center",
      },
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCd",
        width: "7%",
        align: "left",
        sortable: false,
      },
      { text: i18n.tc("label.lbl_lot"), value: "lot", width: "13%", align: "center" },
      {
        text: i18n.tc("label.lbl_instNum"),
        value: "instQuantity",
        width: "9%",
        align: "right",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_achieveQty"),
        value: "actualQuantity",
        width: "9%",
        align: "center",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_difference"),
        value: "subtraction",
        width: "9%",
        align: "right",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "5%",
        align: "center",
      },
    ],

    rules: {
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
    },
  }),

  updated() {
    // 画面が再描画されたとき
    if (this.moveFocusFlag) {
      // フォーカス移動
      this.setCursor();
      this.moveFocusFlag = false;
    }
  },

  created() {
    // 入庫実績APIの検索条件を取得する。
    // 入庫伝票SID
    this.arrSid = this.$route.params.arrSid;
    // 入荷元SID
    this.fromSid = this.$route.params.fromSid;
    // 入庫日（表示用）
    this.enteringAchieveDate = this.$route.params.indexEnteringAchieveDateJ;
    // 入庫日（API）
    this.enteringAchieveDateApi = this.$route.params.indexEnteringAchieveDate;
    // 入荷No
    this.txt_slipNo = this.$route.params.indexTxt_slipNo;
    // 品質区分
    this.qualitySelected = this.$route.params.indexQualitySelected;
    // 有償無償
    this.isPaid = this.$route.params.indexIsPaid;

    // 入庫実績一覧「検索条件」の値を保持する。
    // 取引先SID
    this.searchSuppliersSelected = this.$route.params.suppliersSelected;
    // 入庫日From
    this.searchenteringAchieveDateFrom = this.$route.params.enteringAchieveDateFrom;
    // 入庫日Fromカレンダー
    this.searchenteringAchieveDateFromCal = this.$route.params.enteringAchieveDateFromCal;
    // 入庫日To
    this.searchenteringAchieveDateTo = this.$route.params.enteringAchieveDateTo;
    // 入庫日Toカレンダー
    this.searchenteringAchieveDateToCal = this.$route.params.enteringAchieveDateToCal;
    // 入荷No
    this.searchTxt_slipNo = this.$route.params.txt_slipNo;
    // 品質区分
    this.searchQualitySelected = this.$route.params.qualitySelected;
    // 有償無償
    this.searchIsPaid = this.$route.params.isPaid;
    // コードマスタ取得
    this.getMstCode();
    // 入庫実績詳細を取得
    this.getApiList();
    // 検索条件部分、取引先名取得
    this.getCustomInfo();
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定
     */
    init() {
      //createdにて実施
    },

    /**
     * 入庫実績詳細を取得（入荷実績差異GETAPI）
     */
    getApiList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // IF項目
      const config = this.$httpClient.createGetApiRequestConfig();

      // 入荷伝票SID
      config.params.inListSid = this.arrSid;
      // 工程コード
      config.params.procCd = "200";

      // console.debug("getApiList() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_INDIFFERENC, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("getApiList() Response", response);

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              const list = jsonData.resIdv.inDifference;

              for (var j = 0; j < list.length; j++) {
                // API結果：入庫実績明細
                let apiDataItem = list[j];
                // API結果保持変数
                let inputListItem = {};
                // No
                inputListItem.No = j + 1;
                // 入荷伝票明細SID
                inputListItem.inListSubSid = apiDataItem.inListSubSid;
                // 入荷伝票明細No
                inputListItem.inListSubNo = apiDataItem.inListSubNo;
                // 品番
                inputListItem.itemCd = apiDataItem.itemCd;
                // 品名
                inputListItem.itemName = apiDataItem.itemName;
                // 商品SID
                inputListItem.itemStandardSid = apiDataItem.itemStandardSid;
                let productQtyNum = apiDataItem.inScheduleQuantity;
                // 入荷予定数(必須)
                inputListItem.instQuantity = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );
                // 入荷実績数
                let actualQuantity = apiDataItem.actualQuantity;
                inputListItem.actualQuantity = String(
                  commonUtil.formatToCurrency(Number(actualQuantity))
                );
                inputListItem.oriActualQuantity = String(
                  commonUtil.formatToCurrency(Number(actualQuantity))
                );
                // 差
                let subtraction = apiDataItem.subtraction;
                inputListItem.subtraction = String(
                  commonUtil.formatToCurrency(Number(subtraction))
                );
                inputListItem.oriSubtraction = String(
                  commonUtil.formatToCurrency(Number(subtraction))
                );
                // InputListに格納
                this.inputList.push(inputListItem);
              }

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-ENT-004_002_W");
    },

    /**
     * 一覧画面へ渡す値を設定
     */
    updateOk() {
      this.updateDialogMessage = false;

      this.$router.push({
        name: appConfig.MENU_ID.P_ENT_ACTUAL_LIST,
        params: {
          // 入庫実績一覧「検索条件」の値を返す。
          // 取引先SID
          searchSuppliersSelected: this.searchSuppliersSelected,
          // 入荷No
          searchTxt_slipNo: this.searchTxt_slipNo,
          // 入庫日From
          searchenteringAchieveDateFrom: this.searchenteringAchieveDateFrom,
          // 入庫日Fromカレンダー
          searchenteringAchieveDateFromCal: this.searchenteringAchieveDateFromCal,
          // 入庫日To
          searchenteringAchieveDateTo: this.searchenteringAchieveDateTo,
          // 入庫日Toカレンダー
          searchenteringAchieveDateToCal: this.searchenteringAchieveDateToCal,
          // 品質区分
          searchQualitySelected: this.searchQualitySelected,
          // 有償無償
          searchIsPaid: this.searchIsPaid,
        },
      });
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 入力カンマ処理 実績数
     */
    changeQty(val, index) {
      var ActualQuantity = commonUtil.zen2han(val.actualQuantity).replaceAll(",", "");
      var inScheduleQuantity = commonUtil.zen2han(val.instQuantity).replaceAll(",", "");
      if (!isNaN(Number(ActualQuantity))) {
        this.inputList[index].subtraction = ActualQuantity - inScheduleQuantity;
        this.inputList[index].actualQuantity = commonUtil.formatToCurrency(Number(ActualQuantity));
        this.inputList[index].subtraction = commonUtil.formatToCurrency(
          Number(this.inputList[index].subtraction)
        );
      }
    },

    /**
     * ロケーション予約画面：登録後処理
     */
    saveLocation(data) {
      // 倉庫名
      this.warehouse = data;
      // 画面表示用ロケーション(ロケーションNo + 倉庫名称表示用)
      this.location = data.location + "（" + data.warehouseNameShort + "）";
      // ロケーションSID
      this.locationSid = data.locationSid;
      // ダイアログを閉じる
      this.dialogLocation = false;
    },

    /**
     * 検索条件部分取引先、取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 取引先
          this.supplierList = result[0];

          for (let i = 0; i < this.supplierList.length; i++) {
            if (this.searchSuppliersSelected == this.supplierList[i].value) {
              this.suppliersSelected = this.supplierList[i].text;
            }
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * コードマスタ取得
     */
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 有償無償区分
      const freeCostList = getParameter.getCodeMst(appConfig.CODETYPE.IN_FREECOST_DIV);
      Promise.all([freeCostList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.freeCostDivList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * ロケーション予約画面処理
     */
    addLocation() {
      if (this.inputList[this.editedIndex].warehouse == {}) {
        // ロケ登録なし
        this.locInfo.warehouse = "";
        this.locInfo.location = "";
        this.locInfo.productCd = "";
        this.locInfo.qty = 0;
        this.locInfo.init = true;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.enteringAchieveDateuleDate);
      } else {
        // ロケ登録あり
        this.locInfo.location = this.inputList[this.editedIndex].location;
        this.locInfo.productCd = this.inputList[this.editedIndex].productCd;
        this.locInfo.init = false;
        this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.enteringAchieveDateuleDate);
      }
      this.locInfo.referenceDate = dateTimeHelper.convertUTC(this.enteringAchieveDateuleDate);
      this.locInfo.locProductCd = this.inputList[this.editedIndex].productCd;
      this.dialogLocation = true;
    },

    /**
     * 登録ボタン押下
     */
    updateInsert() {
      this.addFlg = true;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-ENT-004_001_C");
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
    },

    /**
     * 工程実績API（POST）
     */
    updateInsertApi() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_ENT_004;

      // 入庫実績詳細List
      var bodyList = [];

      for (var i = 0; i < this.inputList.length; i++) {
        var inputMap = {};

        inputMap.opeDispName = i18n.tc("label.lbl_enteringAchieveUpdatepc");
        // 営業所SID
        inputMap.officeSid = sessionStorage.getItem("sales_office_sid");
        // 取引先SID
        inputMap.clientSid = this.searchSuppliersSelected;
        // FromSid
        inputMap.fromSid = this.fromSid;
        // toSid
        inputMap.toSid = sessionStorage.getItem("sales_office_sid");
        // 工程コード
        inputMap.procCd = "200";
        // 商品標準SID
        inputMap.itemStandardSid = this.inputList[i].itemStandardSid;
        // 品番
        inputMap.itemCd = this.inputList[i].itemCd;
        // 修正数量
        inputMap.afterStock = this.inputList[i].actualQuantity.replaceAll(",", "");
        // 入荷伝票明細SID
        inputMap.inListSubSid = this.inputList[i].inListSubSid;
        bodyList.push(inputMap);
      }
      body.reqIdv.opeHists = bodyList;
      // console.debug("updateInsertApi() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          // ここがGETとの違い。APIの接続方法としては同じ
          .securePost(appConfig.API_URL.BIZ_OPEHIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("updateInsertApi() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}
</style>
